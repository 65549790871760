<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="6"
        class="px-0 d-none d-lg-block"
      >
        <b-img
          fluid
          :src="imgUrl"
          :style="{ height: '100vh', width: '100%' }"
          alt="Login V2"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="8"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Selamat Datang
          </b-card-title>

          <!-- Error's alert -->
          <b-alert
            v-if="error"
            variant="danger"
            show
          >
            <div class="alert-body font-small-2">
              <p v-text="error"></p>
            </div>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="Kata Sandi"
                label-for="login-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loading"
                v-text="loading ? 'Loading...' : 'Masuk'"
              />
              <hr />
              <b-button
                type="button"
                variant="info"
                block
                :href="gjmfHomepageUrl"
              >
              Website GJMF
            </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import moment from 'moment'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      error: '',
      loading: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/unmul-login3.png'),

      // validation rules
      required,
      email,
      gjmfHomepageUrl: process.env.VUE_APP_GJMF_HOMEPAGE_URL
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/unmul-login3.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true

          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              let { userData } = response.data

              const role = userData.roles[0]
              const ability = role.abilities[0]

              const { action, subject } = ability
              this.$ability.update([ { action, subject } ])

              // Change user's ability to ability (singular) instead of abilities (plural)
              userData = { ...userData, ability: { action, subject } }
              delete userData.abilities

              // Change user's role to role (singular) instead of roles (plural)
              userData = { ...userData, role: role.name }
              delete userData.roles

              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser(role.name))
                .then(() => {
                  const time = moment().format('HH:mm:ss')
                  let greetTime

                  if (time >= '05:00:00' && time < '11:00:00') {
                    greetTime = 'pagi'
                  } else if (time >= '11:00:00' && time < '16:00:00') {
                    greetTime = 'siang'
                  } else if (time >= '16:00:00' && time < '19:00:00') {
                    greetTime = 'sore'
                  } else {
                    greetTime = 'malam'
                  }

                  this.$alert({
                    title: `Selamat ${greetTime} ${userData.full_name}`,
                    icon: 'SmileIcon',
                    variant: 'success',
                    text: 'Semoga semua aktivitas anda berjalan lancar dan menyenangkan.',
                  })
                })
            })
            .catch(error => this.error = error.response.data.message)
            .finally(() => this.loading = false)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
